// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bunlife-js": () => import("/opt/build/repo/src/pages/bunlife.js" /* webpackChunkName: "component---src-pages-bunlife-js" */),
  "component---src-pages-designs-js": () => import("/opt/build/repo/src/pages/designs.js" /* webpackChunkName: "component---src-pages-designs-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadgenerationpanel-js": () => import("/opt/build/repo/src/pages/leadgenerationpanel.js" /* webpackChunkName: "component---src-pages-leadgenerationpanel-js" */),
  "component---src-pages-moor-js": () => import("/opt/build/repo/src/pages/moor.js" /* webpackChunkName: "component---src-pages-moor-js" */),
  "component---src-pages-pathwaystosuccess-js": () => import("/opt/build/repo/src/pages/pathwaystosuccess.js" /* webpackChunkName: "component---src-pages-pathwaystosuccess-js" */),
  "component---src-pages-piecesofher-js": () => import("/opt/build/repo/src/pages/piecesofher.js" /* webpackChunkName: "component---src-pages-piecesofher-js" */),
  "component---src-pages-whgbr-js": () => import("/opt/build/repo/src/pages/whgbr.js" /* webpackChunkName: "component---src-pages-whgbr-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

